import React, { FC } from 'react'

import { StandingsForm } from './renderers/StandingsForm/StandingsForm'
import { StandingsTeam } from './renderers/StandingsTeam/StandingsTeam'
import {
  StandingsColumnInterface,
  StandingsRowInterface,
  ColumnVariants
} from './Standings.interface'
import styles from './StandingsCell.module.scss'

interface StandingsCellProps {
  column: StandingsColumnInterface
  row: StandingsRowInterface
  placement: number
}

export const StandingsCell: FC<StandingsCellProps> = ({
  column,
  row,
  placement
}) => {
  if (!column?.field) return null

  switch (column.variant) {
    case ColumnVariants.bold:
      return <div className={styles.Bold}>{(row as any)[column.field]}</div>
    case ColumnVariants.form:
      return <StandingsForm column={column} row={row} />
    case ColumnVariants.team:
      return <StandingsTeam column={column} row={row} placement={placement} />
    default:
      return (
        <div className={styles.DefaultCell}>{(row as any)[column.field]}</div>
      )
  }
}
