export enum ColumnVariants {
  bold = 'bold',
  form = 'form',
  team = 'team'
}

export interface StandingsColumnInterface {
  index: number
  label: string
  field: string
  variant?: ColumnVariants | undefined
}

export interface StandingsRowInterface {
  id?: number
  MatchClassId?: string
  MatchGroupId?: string
  DisplayOrder?: string
  Position?: string
  UpdateTimeStamp?: string
  a?: string
  b?: string
  c?: string
  d?: string
  e?: string
  f?: string
  g?: string
  h?: string
  i?: string
  j?: string
  isLastBeforeDivider?: boolean
  image?: string
  subdomain?: string
  selectedTeam?: string
}

export interface StandingsInterface {
  groupId: string | number
  groupName: string
  columns: StandingsColumnInterface[]
  rows: StandingsRowInterface[]
}
