import React, { FC, useEffect, useState } from 'react'
import {
  StandingsInterface,
  StandingsRowInterface
} from './Standings.interface'
import { StandingsTable } from './StandingsTable'
import styles from './Standings.module.scss'
import { Loader } from '../../Loader/Loader'
import {
  EventForStats,
  getAllChannelEventsForStats
} from 'api/multistageTournament/getChannelEvents'
import { getStandingsFromService } from 'api/standings/getStandingsFromService'
import ContentSlider from 'components/sliders/ContentSlider'

interface StandingsProps {
  channelSlug: string
  showExpandedToggle: boolean
  teamSlug?: string
}

export const Standings: FC<StandingsProps> = (props) => {
  const { channelSlug, showExpandedToggle, teamSlug } = props
  const slug = channelSlug
  const [events, setEvents] = useState<EventForStats[]>([])
  const [tablesData, setTablesData] = useState<StandingsInterface[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isError, setIsError] = useState<boolean>(false)

  useEffect(() => {
    if (events.length <= 0 && slug) {
      getAllChannelEventsForStats(slug)
        .then((response: EventForStats[]) => {
          setEvents(response)
        })
        .catch((error) => {
          console.log(error)
          setIsLoading(false)
        })
    }
  }, [slug])

  useEffect(() => {
    if (events.length > 0 && tablesData.length <= 0) {
      events.map((event: EventForStats) => {
        getStandingsFromService({
          externalSystemId: event.external_system,
          eventReference: event.external_reference,
          groupReferences: event.groups?.map(
            (group: any) => group.external_reference
          )
        })
          .then((response: any[]) => {
            setTablesData([...tablesData, ...response])
          })
          .catch((error) => {
            setIsLoading(false)
          })
      })
    }
  }, [slug, events])

  useEffect(() => {
    if (tablesData.length > 0) {
      setIsLoading(false)
    }
  }, [tablesData])

  if (isLoading && !isError) {
    return <Loader />
  }

  if (tablesData.length > 0) {
    return (
      <div className={styles.StandingsContainer}>
        <ContentSlider leftPadding={true} showArrows useLightBackground={true}>
          {tablesData.map((standings: StandingsInterface, index: number) => (
            <div className={styles.TableWrapper} key={`Standings__${index}`}>
              <StandingsTable
                standings={standings}
                showExpandedToggle={showExpandedToggle}
                teamSlug={teamSlug}
              />
            </div>
          ))}
        </ContentSlider>
      </div>
    )
  } else {
    return null
  }
}
