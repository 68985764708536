import classNames from 'classnames'
import React, { FC } from 'react'

import {
  StandingsColumnInterface,
  StandingsRowInterface
} from '../../Standings.interface'
import styles from './StandingsForm.module.scss'

interface StandingsFormProps {
  column: StandingsColumnInterface
  row: StandingsRowInterface
}

export const StandingsForm: FC<StandingsFormProps> = ({ column, row }) => {
  const form: any = (row as any)[column.field]
  let chars = Array(5)
    .fill('?')
    .map((char, index) => (form[index] ? form[index] : char))

  chars.reverse()

  return (
    <div className={styles.StandingsForm} data-testid="standings-form">
      {chars.map((char, index) => {
        return (
          <div
            className={classNames(styles.StandingsFormBead, {
              [styles.Undecided]: char === 'U',
              [styles.Win]: char === 'W',
              [styles.Loss]: char === 'L',
              [styles.Draw]: char === 'D',
              [styles.Unknown]: char === '?'
            })}
            key={`StandingsFormBead__${row.id}__${index}`}
          >
            {char}
          </div>
        )
      })}
    </div>
  )
}
