import { StandingsColumnInterface, ColumnVariants } from './Standings.interface'

export const createGridTemplateColumns = (
  columns: StandingsColumnInterface[],
  isMobile: boolean
) => {
  return columns
    .map(({ variant }) => {
      switch (variant) {
        case ColumnVariants.team:
          return '300px'
        case ColumnVariants.form:
          return isMobile ? '152px' : '200px'
        case ColumnVariants.bold:
        default:
          return isMobile ? '32px' : '40px'
      }
    })
    .join(' ')
}
