import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import { MediaObject } from '../interfaces'
import useCurrentUser from './CurrentUser'

export type AccessTypes = {
  isSuperadmin: boolean
  isTechAdmin: boolean
  isAdmin: boolean
  isMediadownload: boolean
  hasChannelAccess: boolean
  canView: boolean
  canDownload: boolean
}

const defaultAccess: AccessTypes = {
  isSuperadmin: false,
  isTechAdmin: false,
  isAdmin: false,
  isMediadownload: false,
  hasChannelAccess: false,
  canView: false,
  canDownload: false
}

export default function useAdminAccess(
  mediaObject?: MediaObject | undefined
): AccessTypes {
  const currentChannel = useSelector(channelSelector)
  const currentCompany = currentChannel.data
  const currentUser = useCurrentUser()
  const [access, setAccess] = useState<AccessTypes>(defaultAccess)

  useEffect(() => {
    const superAccess = (): boolean => {
      return ['supertech', 'supersale', 'superadmin'].some((role: string) =>
        currentUser?.roles?.includes(role)
      )
    }

    const superTech = (): boolean =>
      ['supertech'].some((role: string) => currentUser?.roles.includes(role))

    const adminAccess = (): boolean => {
      return ['sales', 'csm', 'support', 'externalsupport'].some(
        (role: string) => currentUser?.roles?.includes(role)
      )
    }
    const channelAdminAccess = (): boolean => {
      if (!currentUser || !currentUser?.companies) {
        return false
      }
      return (
        currentUser?.companies
          .map((c: any) => c.subdomain)
          .includes(currentCompany?.subdomain || '') || false
      )
    }
    const viewerAccess = (): boolean => {
      return ['superviewer', 'mediaviewer'].some((role: string) =>
        currentUser?.roles?.includes(role)
      )
    }

    const mediaDownloadAccess = (): boolean => {
      return ['mediadownload'].some((role: string) =>
        currentUser?.roles?.includes(role)
      )
    }

    const downloadAccess = (): boolean => {
      if (mediaDownloadAccess()) {
        return true
      }

      if (mediaObject?.game && currentUser?.companies) {
        const uniq = (arr: Array<any>) =>
          arr.filter((item, index) => arr.indexOf(item) === index)

        const isLeagueAdmin =
          uniq(
            currentUser?.companies
              .map(
                (user_company) =>
                  user_company.leagues &&
                  user_company.leagues.map((league) => league.id)
              )
              .flat() || []
          ).includes(mediaObject.event_schedule?.id) || false

        return isLeagueAdmin
      }

      return false
    }

    if (currentUser) {
      setAccess({
        isSuperadmin: superAccess(),
        isTechAdmin: superTech(),
        isAdmin: adminAccess() || superAccess(),
        isMediadownload: mediaDownloadAccess(),
        hasChannelAccess:
          channelAdminAccess() || adminAccess() || superAccess(),
        canView:
          viewerAccess() ||
          channelAdminAccess() ||
          adminAccess() ||
          superAccess(),
        canDownload:
          downloadAccess() ||
          channelAdminAccess() ||
          adminAccess() ||
          superAccess()
      })
    } else {
      setAccess(defaultAccess)
    }
  }, [currentUser, currentCompany, mediaObject])
  return access
}
