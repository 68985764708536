import React from 'react'
import { Divider } from 'components/page/header/components/hamburgerMenuContent/divider/Divider'

export const getFormatedTextForStructuredPlainText = (text: string) => {
  const splitTextArray = text ? text.split(/\r?\n/) : []
  const splitText = splitTextArray.map((text: string, i: number) => {
    const key = `${text}-${i}`
    if (/^-{3,}$/.test(text.trim())) {
      return <Divider key={key} />
    }
    if (text.trim() === '') {
      return <br key={key} />
    }
    if (text) return <div key={key}>{text}</div>
  })
  return splitText
}
