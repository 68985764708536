import { StandingsInterface } from 'components/content/Standings/Standings.interface'

const BASE_URL = process.env.REACT_APP_STANDINGS_ENDPOINT

export type getStandingsFromServiceProps = {
  externalSystemId: string
  eventReference: string
  groupReferences?: Array<string | number>
}

export const getStandingsFromService = async (
  props: getStandingsFromServiceProps
): Promise<StandingsInterface[]> => {
  const { externalSystemId, eventReference, groupReferences } = props
  let query = `externalSystemId=${externalSystemId}&eventReference=${eventReference}`
  if (groupReferences && groupReferences.length > 0) {
    groupReferences.forEach((groupId) => {
      query = query.concat(`&groupReferences[]=${groupId}`)
    })
  }
  const url = `${BASE_URL}/getFromEventReference?${query}`
  const response = await fetch(url)
  const data = await response.json()
  return data
}
